/* Allow Vertical Scrolling but do not show scrollbar for correct sizing of RDT tables */
.no-scroll-bar::-webkit-scrollbar {
  width: 0px;
}

.pg-label {
  font-size: 12px !important;
  font-family: 'Roboto' !important;
  font-weight: 800 !important;
  color: lightslategray;
  margin-left: 5px;
  text-transform: uppercase;
  letter-spacing: 2px;
}

/* OVERRIDE FOR CHAKRA - NEEDED FOR EMBEDDED WINDOW TO INHERIT PARENT COLOR */
.chakra-ui-light {
  background-color: transparent;
}

/* Override for transaction table css (fixes header column misalignment). */
.cSyyNR {
  flex: 0 0 47px !important;
}

/* .hCTMpS {
  margin-left: 0px !important;
}  */


