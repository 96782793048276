@media only screen and (max-width: 800px) {
  .LoginImageContainer {
    display: none;
  }
  .LoginCentre {
    flex: 100%;
  }
}

.LoginCentre {
  display: flex;
  align-items: center;
  justify-content: center;
}
